@import '~antd/dist/antd.less';
@import '~slick-carousel/slick/slick.less';
@import '~slick-carousel/slick/slick-theme.less';

html,
body {
  font-family: 'Montserrat', sans-serif !important;
}

.login-form {
  * {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: #fff;
      -webkit-box-shadow: none;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

@primary-color: #1890ff;@link-color: #1890ff;